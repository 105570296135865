import React from "react";
import { graphql } from "gatsby";

import { Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap-grid.min.css';

import Layout from "../components/secondlayout";
import { RichText } from "prismic-reactjs";


function TermsTemplate({ data, pageContext: { lang, currentPath } }) {

    return (
  <Layout  wpLang={lang} currentPath={currentPath} featuredImagePath="above-min.png">
    <Row className="single-page">
    <div className="entry-content">
    <RichText render={data.prismicTermsAndConditions.data.title.raw} />
    <RichText render={data.prismicTermsAndConditions.data.content.raw} />
</div>

    </Row>
  </Layout>
    );
}
export const query = graphql`
query terms($lang: String) {
    prismicTermsAndConditions(lang: { eq: $lang }) {
    data {
        content {
          html
          raw
          text
        }
        title {
          html
          raw
          text
        }
      }
      _previewable
      url
      lang
      id
    }
  
}
`;
export default TermsTemplate